<template lang="pug">
 v-container(fluid).primario
    v-row.primario.d-none.d-md-flex
        v-col(align='center' justify='center')
            h3.white--text.font-weight-light Paola Vidente Ⓒ Copyright {{año}}
      
    v-row.primario.d-md-none.justify-center.align-center
        v-col(cols=12 )
            p.white--text.text-center Paola Vidente Ⓒ Copyright {{año}}  
</template>
<script>
export default {
    data: ()=>({
        año: new Date().getFullYear()
    }),
}
</script>